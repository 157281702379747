<template lang="pug">
  Card(title="Change Password", type="form", method="PUT", endpoint="settings/change-password", :data="data", success-message, @success="onSuccess")
    InputGroup(name="current_password", label="Current Password"): PasswordInput(v-model="data.current_password")
    InputGroup(name="new_password", label="New Password"): PasswordInput(v-model="data.new_password")
    InputGroup(name="new_password_confirmation", label="Confirm New Password"): PasswordInput(v-model="data.new_password_confirmation")
</template>

<script>
export default {
  metaInfo() {
    return { title: this.title }
  },
  data: () => ({
    title: "Security Settings",
    data: {
      current_password: "",
      new_password: "",
      new_password_confirmation: ""
    }
  }),
  methods: {
    onSuccess() {
      this.data.current_password = ""
      this.data.new_password = ""
      this.data.new_password_confirmation = ""
    }
  }
}
</script>
