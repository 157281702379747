<template lang="pug">
  Card(title="API")
    InputGroup(name="api_key", label="API Key")
      TextInput(v-model="data.api_key", readonly)
        template(v-slot:append)
          CopyToClipboardIcon(:content="data.api_key")

    InputGroup(name="api_secret", label="API Secret")
      TextInput(v-model="data.api_secret", readonly)
        template(v-slot:append)
          CopyToClipboardIcon(:content="data.api_secret")
</template>

<script>
export default {
  metaInfo() {
    return { title: this.title }
  },
  data: () => ({
    title: "API Settings",
    data: {
      api_key: "",
      api_secret: ""
    }
  }),
  async mounted() {
    let response = await this.$api.get("settings/api")
    this.data = response.data
  }
}
</script>
