<template lang="pug">
  Card(title="Analytics", type="form", method="PUT", endpoint="settings/analytics", :data="data", success-message)
    InputGroup(name="ip_exclusions", label="IP(s) to exclude", description="Press 'enter' after each IP")
      TagsInput(v-model="data.analytics_ip_exclusions", placeholder="Enter IP...")
</template>

<script>
export default {
  metaInfo() {
    return { title: this.title }
  },
  data: () => ({
    title: "Analytics Settings",
    data: {
      analytics_ip_exclusions: []
    }
  }),
  async mounted() {
    let response = await this.$api.get("settings/analytics")

    if (Array.isArray(response.data)) {
      this.data.analytics_ip_exclusions = response.data
    }
  }
}
</script>
