<template lang="pug">
  Card(title="Landing Page", type="form", method="PUT", endpoint="settings/landing-page", :data="data", success-message)
    InputGroup(name="display_contact_info_on_links", label="Contact Information", v-if="user.hasPermission('settings.contact_bar_settings')")
      Radios(v-model="data.display_contact_info_on_links")
        Radio(value="personal") Include my personal contact information
        Radio(value="company") Include company contact information
        Radio(value="none") Don't include any contact information

    InputGroup(
      name="landing_page_email_template",
      label="Email Template",
      description="When modifying your template, do not delete or modify &#123;&#123;collectionLink&#125;&#125;, as it is required to automatically pull in the link to the appropriate collection. As for &#123;&#123;userName&#125;&#125;, if you choose to keep it, it will automatically pull in your full name; delete it if you want to use a custom sign-off."
    )
      TextInput(v-model="data.landing_page_email_template", textarea)
</template>

<script>
import { mapGetters } from "vuex"

export default {
  metaInfo() {
    return { title: this.title }
  },
  data: () => ({
    title: "Landing Page Settings",
    data: {}
  }),
  computed: {
    ...mapGetters({
      user: "auth/user"
    })
  },
  async mounted() {
    let response = await this.$api.get("settings/landing-page")
    let { display_contact_info_on_links, landing_page_email_template } = response.data

    this.$set(this.data, "landing_page_email_template", landing_page_email_template)

    if (this.user.hasPermission("settings.contact_bar_settings")) {
      this.$set(this.data, "display_contact_info_on_links", display_contact_info_on_links)
    }
  }
}
</script>
