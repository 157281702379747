<template lang="pug">
  div
    Card(title="Contact Information", type="form", method="PUT", endpoint="settings/contact-information", :data="contactInformation", success-message)
      //Row(md="row")
        Column(class="md:w-1/2"): InputGroup(name="first_name", label="First Name"): TextInput(v-model="contactInformation.first_name")
        Column(class="md:w-1/2"): InputGroup(name="last_name", label="Last Name"): TextInput(v-model="contactInformation.last_name")
      InputGroup(name="email", label="Email Address"): TextInput(v-model="contactInformation.email", prepend-icon="envelope")
      InputGroup(name="phone", label="Phone"): TextInput(v-model="contactInformation.phone", prepend-icon="phone-alt")
</template>

<script>
import { mapGetters } from "vuex"

export default {
  metaInfo() {
    return { title: this.title }
  },
  data: () => ({
    title: "Account Settings",
    contactInformation: {
      // first_name: "",
      // last_name: "",
      email: "",
      phone: ""
    },
    logo: {
      url: ""
    }
  }),
  computed: {
    ...mapGetters({
      user: "auth/user"
    }),
    logoType: function () {
      if (this.user.logo) return "user"
      if (!this.user.logo && this.user.partner.logo) return "partner"
      return null
    }
  },
  async mounted() {
    let contactInformation = await this.$api.get("settings/contact-information")
    this.contactInformation = contactInformation.data
  }
}
</script>
