<template lang="pug">
  AppLayout
    Band(contained, centered)

      Row(lg="row")
        Column(class="lg:w-1/4")
          Navigation.settings-navigation
            NavigationLink(:to="{name: 'settings.account'}", icon="user-circle") Account
            NavigationLink(:to="{name: 'settings.landing-page'}", icon="browser") Landing Page
            NavigationLink(:to="{name: 'settings.api'}", icon="server", v-if="user.hasPermission('sharing.api_access')") API
            NavigationLink(:to="{name: 'settings.analytics'}", icon="analytics", v-if="user.hasPermission('analytics.manage_settings')") Analytics
            NavigationLink(:to="{name: 'settings.security'}", icon="user-lock") Security

        Column(class="lg:w-3/4")
          router-view
</template>

<script>
import { mapGetters } from "vuex"

export default {
  metaInfo() {
    return { title: this.title }
  },
  data: () => ({
    title: "Settings"
  }),
  computed: {
    ...mapGetters({
      user: "auth/user"
    })
  }
}
</script>

<style>
.settings-navigation {
  @apply text-lg;

  .navigation-link {
    @apply px-5 py-3;

    &-icon {
      @apply mr-4 text-xl;
    }

    &.router-link-exact-active {
      @apply rounded bg-gray-100;
    }
  }
}
</style>
